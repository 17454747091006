import StateDropTarget from '../../../drag-and-drop/StateDroppable';

const AccrualFields = () => {
  return (
    <>
      State Accrual Variables
      <StateDropTarget />
    </>
  );
};

export default AccrualFields;
