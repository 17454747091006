// @generated by Peggy 4.0.3.
//
// https://peggyjs.org/


function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  var self = Error.call(this, message);
  // istanbul ignore next Check is a necessary evil to support older environments
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, peg$SyntaxError.prototype);
  }
  self.expected = expected;
  self.found = found;
  self.location = location;
  self.name = "SyntaxError";
  return self;
}

peg$subclass(peg$SyntaxError, Error);

function peg$padEnd(str, targetLength, padString) {
  padString = padString || " ";
  if (str.length > targetLength) { return str; }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

peg$SyntaxError.prototype.format = function(sources) {
  var str = "Error: " + this.message;
  if (this.location) {
    var src = null;
    var k;
    for (k = 0; k < sources.length; k++) {
      if (sources[k].source === this.location.source) {
        src = sources[k].text.split(/\r\n|\n|\r/g);
        break;
      }
    }
    var s = this.location.start;
    var offset_s = (this.location.source && (typeof this.location.source.offset === "function"))
      ? this.location.source.offset(s)
      : s;
    var loc = this.location.source + ":" + offset_s.line + ":" + offset_s.column;
    if (src) {
      var e = this.location.end;
      var filler = peg$padEnd("", offset_s.line.toString().length, ' ');
      var line = src[s.line - 1];
      var last = s.line === e.line ? e.column : line.length + 1;
      var hatLen = (last - s.column) || 1;
      str += "\n --> " + loc + "\n"
          + filler + " |\n"
          + offset_s.line + " | " + line + "\n"
          + filler + " | " + peg$padEnd("", s.column - 1, ' ')
          + peg$padEnd("", hatLen, "^");
    } else {
      str += "\n at " + loc;
    }
  }
  return str;
};

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};
  var peg$source = options.grammarSource;

  var peg$startRuleFunctions = { Start: peg$parseStart };
  var peg$startRuleFunction = peg$parseStart;

  var peg$c0 = "\n";
  var peg$c1 = "\r\n";
  var peg$c2 = "//";
  var peg$c3 = "!";
  var peg$c4 = "C ";
  var peg$c5 = "**";
  var peg$c6 = "/";
  var peg$c7 = ".";
  var peg$c8 = "0";
  var peg$c9 = "e";
  var peg$c10 = "#";
  var peg$c11 = "action";
  var peg$c12 = "alias";
  var peg$c13 = "as";
  var peg$c14 = "end";
  var peg$c15 = " time";
  var peg$c16 = "f";
  var peg$c17 = "false";
  var peg$c18 = "function";
  var peg$c19 = "include";
  var peg$c20 = "initiator";
  var peg$c21 = "s";
  var peg$c22 = "if";
  var peg$c23 = "is";
  var peg$c24 = "lookup variable";
  var peg$c25 = "off";
  var peg$c26 = "on";
  var peg$c27 = "parameter change";
  var peg$c28 = "parameter file";
  var peg$c29 = "plotfil";
  var peg$c30 = "sensitivity";
  var peg$c31 = "set";
  var peg$c32 = "si";
  var peg$c33 = "t";
  var peg$c34 = "TIMER";
  var peg$c35 = "title";
  var peg$c36 = "true";
  var peg$c37 = "userevt";
  var peg$c38 = "when";
  var peg$c39 = ",";
  var peg$c40 = "(";
  var peg$c41 = ")";
  var peg$c42 = ">=";
  var peg$c43 = "<=";
  var peg$c44 = "AND";
  var peg$c45 = "=";

  var peg$r0 = /^[\t\v-\f \xA0\uFEFF]/;
  var peg$r1 = /^[\n\r\u2028\u2029]/;
  var peg$r2 = /^[\r\u2028-\u2029]/;
  var peg$r3 = /^[$A-Z\\_a-z]/;
  var peg$r4 = /^[a-zA-Z0-9]/;
  var peg$r5 = /^[$0-9A-Z\\_a-z]/;
  var peg$r6 = /^[0-9]/;
  var peg$r7 = /^[1-9]/;
  var peg$r8 = /^[+\-]/;
  var peg$r9 = /^[FT]/i;
  var peg$r10 = /^[a-zA-Z]/;
  var peg$r11 = /^[a-zA-Z0-9:()|]/;
  var peg$r12 = /^[*\/]/;
  var peg$r13 = /^[+\-<>]/;

  var peg$e0 = peg$anyExpectation();
  var peg$e1 = peg$classExpectation(["\t", ["\v", "\f"], " ", "\xA0", "\uFEFF"], false, false);
  var peg$e2 = peg$classExpectation(["\n", "\r", "\u2028", "\u2029"], false, false);
  var peg$e3 = peg$literalExpectation("\n", false);
  var peg$e4 = peg$literalExpectation("\r\n", false);
  var peg$e5 = peg$classExpectation(["\r", ["\u2028", "\u2029"]], false, false);
  var peg$e6 = peg$literalExpectation("//", false);
  var peg$e7 = peg$literalExpectation("!", false);
  var peg$e8 = peg$literalExpectation("C ", false);
  var peg$e9 = peg$literalExpectation("**", false);
  var peg$e10 = peg$classExpectation(["$", ["A", "Z"], "\\", "_", ["a", "z"]], false, false);
  var peg$e11 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"]], false, false);
  var peg$e12 = peg$literalExpectation("/", false);
  var peg$e13 = peg$classExpectation(["$", ["0", "9"], ["A", "Z"], "\\", "_", ["a", "z"]], false, false);
  var peg$e14 = peg$literalExpectation(".", false);
  var peg$e15 = peg$literalExpectation("0", false);
  var peg$e16 = peg$classExpectation([["0", "9"]], false, false);
  var peg$e17 = peg$classExpectation([["1", "9"]], false, false);
  var peg$e18 = peg$literalExpectation("e", true);
  var peg$e19 = peg$classExpectation(["+", "-"], false, false);
  var peg$e20 = peg$classExpectation(["F", "T"], false, true);
  var peg$e21 = peg$classExpectation([["a", "z"], ["A", "Z"]], false, false);
  var peg$e22 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], ":", "(", ")", "|"], false, false);
  var peg$e23 = peg$literalExpectation("#", false);
  var peg$e24 = peg$literalExpectation("ACTION", true);
  var peg$e25 = peg$literalExpectation("ALIAS", true);
  var peg$e26 = peg$literalExpectation("AS", true);
  var peg$e27 = peg$literalExpectation("END", true);
  var peg$e28 = peg$literalExpectation(" TIME", true);
  var peg$e29 = peg$literalExpectation("F", true);
  var peg$e30 = peg$literalExpectation("FALSE", true);
  var peg$e31 = peg$literalExpectation("FUNCTION", true);
  var peg$e32 = peg$literalExpectation("INCLUDE", true);
  var peg$e33 = peg$literalExpectation("INITIATOR", true);
  var peg$e34 = peg$literalExpectation("S", true);
  var peg$e35 = peg$literalExpectation("IF", true);
  var peg$e36 = peg$literalExpectation("IS", true);
  var peg$e37 = peg$literalExpectation("LOOKUP VARIABLE", true);
  var peg$e38 = peg$literalExpectation("OFF", true);
  var peg$e39 = peg$literalExpectation("ON", true);
  var peg$e40 = peg$literalExpectation("PARAMETER CHANGE", true);
  var peg$e41 = peg$literalExpectation("PARAMETER FILE", true);
  var peg$e42 = peg$literalExpectation("PLOTFIL", true);
  var peg$e43 = peg$literalExpectation("SENSITIVITY", true);
  var peg$e44 = peg$literalExpectation("SET", true);
  var peg$e45 = peg$literalExpectation("SI", true);
  var peg$e46 = peg$literalExpectation("T", true);
  var peg$e47 = peg$literalExpectation("TIMER", false);
  var peg$e48 = peg$literalExpectation("TITLE", true);
  var peg$e49 = peg$literalExpectation("TRUE", true);
  var peg$e50 = peg$literalExpectation("USEREVT", true);
  var peg$e51 = peg$literalExpectation("WHEN", true);
  var peg$e52 = peg$literalExpectation(",", false);
  var peg$e53 = peg$literalExpectation("(", false);
  var peg$e54 = peg$literalExpectation(")", false);
  var peg$e55 = peg$classExpectation(["*", "/"], false, false);
  var peg$e56 = peg$literalExpectation(">=", false);
  var peg$e57 = peg$literalExpectation("<=", false);
  var peg$e58 = peg$classExpectation(["+", "-", "<", ">"], false, false);
  var peg$e59 = peg$literalExpectation("AND", false);
  var peg$e60 = peg$literalExpectation("=", false);

  var peg$f0 = function(program) {
	// Switch the comments on the following lines to disable locations for debugging

	return program;
	// return stripLocations(program);
};
  var peg$f1 = function(v) {
	return {
    	type: "comment",
        value: extractList(v,1).join(''),
    }
};
  var peg$f2 = function(first, rest) {
	let units = first.join('');
    if (rest) {
    	units += rest[0] + rest[1];
    }
    return units;
};
  var peg$f3 = function(literal, units) {
	return {
    	location: location(),
    	type: "number",
        units: (units || [])[1],
        value: literal,
    }
};
  var peg$f4 = function() {
	return parseFloat(text());
};
  var peg$f5 = function() {
    return parseFloat(text());
};
  var peg$f6 = function() {
    return parseFloat(text());
};
  var peg$f7 = function(v) {
	let value = v === 'TRUE' || v === 'T';
	return {
    	location: location(),
    	type: "boolean",
        value,
    }
};
  var peg$f8 = function(value) {
	return {
    	location: location(),
    	type: "identifier",
        value: value.join(''),
    }
};
  var peg$f9 = function(head, tail) {
	let value = head.join('');
    if (tail) {
    	value += ' ' + extractList(tail, 2).map((item) => item.join('')).join(' ');
    }
	return {
    	location: location(),
        type: "parameter_name",
        value,
    }
};
  var peg$f10 = function(index, flag, value) {
	return {
    	flag: (flag || [])[0],
        location: location(),
        index: Number(index.join('')),
    	type: "parameter",
        value,
    }
};
  var peg$f11 = function(n) {
	return {
    	location: location(),
    	type: "timer",
        value: Number(n.join('')),
    }
};
  var peg$f12 = function() {
	return "INITIATORS";
};
  var peg$f13 = function(v) {
	return v.filter((x) => x.type === "comment");
};
  var peg$f14 = function(v) {
	return v.filter((x) => x.type === "comment");
};
  var peg$f15 = function(value, rest) {
	let args = [value];
    if (rest) {
    	args = args.concat(rest[3]);
    }
	return args;
};
  var peg$f16 = function(value, args) {
	return {
    	arguments: args || [],
        location: location(),
    	type: "call_expression",
        value,
    }
};
  var peg$f17 = function(left, op, right) {
	return {
    	location: location(),
    	type: "expression",
        value: {
        	left,
            op,
            right,
        },
    }
};
  var peg$f18 = function(value) {
	return {
    	location: location(),
    	type: "expression_block",
        value,
    }
};
  var peg$f19 = function(target, value) {
	return {
    	location: location(),
    	target,
    	type: "assignment",
        value,
    }
};
  var peg$f20 = function(target, value) {
	return {
    	location: location(),
    	target,
    	type: "assignment",
        value,
    }
};
  var peg$f21 = function(target, value) {
	return {
    	location: location(),
    	target,
    	type: "is_expression",
        value,
    }
};
  var peg$f22 = function(target, value) {
	return {
    	location: location(),
    	target,
    	type: "as_expression",
        value,
    }
};
  var peg$f23 = function(value) {
    return {
    	location: location(),
        ...value,
    }
};
  var peg$f24 = function(value) {
	return {
    	location: location(),
    	type: "sensitivity",
        value,
    }
};
  var peg$f25 = function(value) {
	return {
    	type: "title",
        value: (value || [])[0],
    }
};
  var peg$f26 = function(first, rest) {
	let title = extractList(first, 1).join('');
    if (rest) {
    	title += '\n' + rest[1];
    }
	return title;
};
  var peg$f27 = function(fileType, v) {
	return {
    	fileType,
    	type: "file",
        value: extractList(v, 1).join(''),
    }
};
  var peg$f28 = function(blockType, value) {
	return {
    	blockType,
        type: "block",
        value: safeValue(value),
    }
};
  var peg$f29 = function(blockType, test, value) {
	return {
    	blockType,
    	test,
    	type: "conditional_block",
        value: safeValue(value),
    }
};
  var peg$f30 = function(value) {
	return {
    	type: "alias",
        value: safeValue(value),
    }
};
  var peg$f31 = function(head, tail) {
	return [head].concat(extractList(tail, 1));
};
  var peg$f32 = function(n, value) {
	return {
    	n: Number(n.join('')),
    	type: "plotfil",
        value: safeValue(value),
    }
};
  var peg$f33 = function(head, tail) {
	let value = [head];
    if (tail && tail.length > 0) {
    	value = value.concat(extractList(tail, 3)[0]);
    }
	return value;
};
  var peg$f34 = function(head, tail) {
	let value = [head];
    if (tail && tail.length > 0) {
    	value = value.concat(extractList(tail, 1)[0]);
    }
	return value;
};
  var peg$f35 = function(value) {
	return {
    	type: "user_evt",
        value: safeValue(value),
    }
};
  var peg$f36 = function(head, tail) {
	return [head].concat(extractList(tail, 1));
};
  var peg$f37 = function(n, value) {
	return {
    	index: Number(n.join('')),
        location: location(),
    	type: "action",
        value: safeValue(value),
    }
};
  var peg$f38 = function(name, value) {
	return {
    	name,
    	type: "function",
        value,
    }
};
  var peg$f39 = function(value) {
	return {
    	type: "set_timer",
        value,
    }
};
  var peg$f40 = function(name, value) {
	return {
		name,
    	type: "lookup_variable",
        value: safeValue(value),
    }
};
  var peg$f41 = function(head, tail) {
	let value = [extractList(head, 1).join('')];
    if (tail && tail.length > 0) {
    	value = value.concat(extractList(tail, 1)[0]);
    }
	return value;
};
  var peg$f42 = function(value) {
	return {
    	type: "program",
        value: value || [],
    }
};
  var peg$f43 = function(head, tail) {
	let re = [head];
    for (let i = 0; i < tail.length; i += 1) {
    	for (let j = 0; j < tail[i][0].length; j += 1) {
        	re = re.concat(tail[i][0][j]);
        }
        re = re.concat(tail[i][1]);
    }
    return re;
};
  var peg$currPos = options.peg$currPos | 0;
  var peg$savedPos = peg$currPos;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$maxFailPos = peg$currPos;
  var peg$maxFailExpected = options.peg$maxFailExpected || [];
  var peg$silentFails = options.peg$silentFails | 0;

  var peg$result;

  if (options.startRule) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return {
      source: peg$source,
      start: peg$savedPos,
      end: peg$currPos
    };
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      if (pos >= peg$posDetailsCache.length) {
        p = peg$posDetailsCache.length - 1;
      } else {
        p = pos;
        while (!peg$posDetailsCache[--p]) {}
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos, endPos, offset) {
    var startPosDetails = peg$computePosDetails(startPos);
    var endPosDetails = peg$computePosDetails(endPos);

    var res = {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
    if (offset && peg$source && (typeof peg$source.offset === "function")) {
      res.start = peg$source.offset(res.start);
      res.end = peg$source.offset(res.end);
    }
    return res;
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseStart() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse__();
    s2 = peg$parseProgram();
    s3 = peg$parse__();
    peg$savedPos = s0;
    s0 = peg$f0(s2);

    return s0;
  }

  function peg$parseSourceCharacter() {
    var s0;

    if (input.length > peg$currPos) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e0); }
    }

    return s0;
  }

  function peg$parseFreeCharacter() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseLineTerminator();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseSourceCharacter();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseWhiteSpace() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r0.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e1); }
    }

    return s0;
  }

  function peg$parseLineTerminator() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r1.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e2); }
    }

    return s0;
  }

  function peg$parseLineTerminatorSequence() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c0;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e3); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c1) {
        s0 = peg$c1;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e4); }
      }
      if (s0 === peg$FAILED) {
        s0 = input.charAt(peg$currPos);
        if (peg$r2.test(s0)) {
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e5); }
        }
      }
    }

    return s0;
  }

  function peg$parseCommentIndicator() {
    var s0;

    if (input.substr(peg$currPos, 2) === peg$c2) {
      s0 = peg$c2;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e6); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s0 = peg$c3;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e7); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c4) {
          s0 = peg$c4;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e8); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c5) {
            s0 = peg$c5;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e9); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSingleLineComment() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseCommentIndicator();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseFreeCharacter();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseFreeCharacter();
      }
      peg$savedPos = s0;
      s0 = peg$f1(s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierStart() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r3.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e10); }
    }

    return s0;
  }

  function peg$parseLiteral() {
    var s0;

    s0 = peg$parseBooleanLiteral();
    if (s0 === peg$FAILED) {
      s0 = peg$parseNumericLiteral();
      if (s0 === peg$FAILED) {
        s0 = peg$parseTimerLiteral();
      }
    }

    return s0;
  }

  function peg$parseUnits() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r4.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e11); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r4.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e11); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c5) {
        s3 = peg$c5;
        peg$currPos += 2;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e9); }
      }
      if (s3 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s3 = peg$c6;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e12); }
        }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parseUnits();
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f2(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNumericLiteral() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseDecimalLiteral();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = input.charAt(peg$currPos);
      if (peg$r5.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e13); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseUnits();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        peg$savedPos = s0;
        s0 = peg$f3(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDecimalLiteral() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseDecimalIntegerLiteral();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 46) {
        s2 = peg$c7;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e14); }
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseDecimalDigit();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseDecimalDigit();
        }
        s4 = peg$parseExponentPart();
        if (s4 === peg$FAILED) {
          s4 = null;
        }
        peg$savedPos = s0;
        s0 = peg$f4();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 46) {
        s1 = peg$c7;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e14); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseDecimalDigit();
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseDecimalDigit();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseExponentPart();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          peg$savedPos = s0;
          s0 = peg$f5();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseDecimalIntegerLiteral();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseExponentPart();
          if (s2 === peg$FAILED) {
            s2 = null;
          }
          peg$savedPos = s0;
          s0 = peg$f6();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parseDecimalIntegerLiteral() {
    var s0, s1, s2, s3;

    if (input.charCodeAt(peg$currPos) === 48) {
      s0 = peg$c8;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e15); }
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseNonZeroDigit();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseDecimalDigit();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseDecimalDigit();
        }
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseDecimalDigit() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r6.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e16); }
    }

    return s0;
  }

  function peg$parseNonZeroDigit() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r7.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e17); }
    }

    return s0;
  }

  function peg$parseExponentPart() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseExponentIndicator();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseSignedInteger();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExponentIndicator() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (s0.toLowerCase() === peg$c9) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e18); }
    }

    return s0;
  }

  function peg$parseSignedInteger() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r8.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e19); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = [];
    s3 = peg$parseDecimalDigit();
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseDecimalDigit();
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = [s1, s2];
      s0 = s1;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBooleanLiteral() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseTRUE();
    if (s1 === peg$FAILED) {
      s1 = peg$parseFALSE();
      if (s1 === peg$FAILED) {
        s1 = input.charAt(peg$currPos);
        if (peg$r9.test(s1)) {
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e20); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = input.charAt(peg$currPos);
      if (peg$r10.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e21); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f7(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseReserved() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseEND();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIS();
      if (s1 === peg$FAILED) {
        s1 = peg$parseAS();
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = input.charAt(peg$currPos);
      if (peg$r10.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e21); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifier() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseReserved();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = input.charAt(peg$currPos);
      if (peg$r4.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e11); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = input.charAt(peg$currPos);
          if (peg$r4.test(s3)) {
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e11); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f8(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseParameterNameCharacter() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r11.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e22); }
    }

    return s0;
  }

  function peg$parseParameterName() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseReserved();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseParameterNameCharacter();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseParameterNameCharacter();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$parse_();
        s6 = peg$currPos;
        peg$silentFails++;
        s7 = peg$parseReserved();
        peg$silentFails--;
        if (s7 === peg$FAILED) {
          s6 = undefined;
        } else {
          peg$currPos = s6;
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = [];
          s8 = peg$parseParameterNameCharacter();
          if (s8 !== peg$FAILED) {
            while (s8 !== peg$FAILED) {
              s7.push(s8);
              s8 = peg$parseParameterNameCharacter();
            }
          } else {
            s7 = peg$FAILED;
          }
          if (s7 !== peg$FAILED) {
            s5 = [s5, s6, s7];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$parse_();
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseReserved();
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = undefined;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = [];
              s8 = peg$parseParameterNameCharacter();
              if (s8 !== peg$FAILED) {
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  s8 = peg$parseParameterNameCharacter();
                }
              } else {
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f9(s2, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseParameter() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r6.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e16); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r6.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e16); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$currPos;
      s4 = peg$parseBooleanLiteral();
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        s4 = [s4, s5];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = peg$parseExpr();
      if (s4 === peg$FAILED) {
        s4 = peg$parseParameterName();
      }
      if (s4 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f10(s1, s3, s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTimerLiteral() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseTIMER();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 35) {
        s3 = peg$c10;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e23); }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = [];
      s5 = input.charAt(peg$currPos);
      if (peg$r6.test(s5)) {
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e16); }
      }
      if (s5 !== peg$FAILED) {
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = input.charAt(peg$currPos);
          if (peg$r6.test(s5)) {
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e16); }
          }
        }
      } else {
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f11(s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseACTION() {
    var s0;

    s0 = input.substr(peg$currPos, 6);
    if (s0.toLowerCase() === peg$c11) {
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e24); }
    }

    return s0;
  }

  function peg$parseALIAS() {
    var s0;

    s0 = input.substr(peg$currPos, 5);
    if (s0.toLowerCase() === peg$c12) {
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e25); }
    }

    return s0;
  }

  function peg$parseAS() {
    var s0;

    s0 = input.substr(peg$currPos, 2);
    if (s0.toLowerCase() === peg$c13) {
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e26); }
    }

    return s0;
  }

  function peg$parseEND() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 3);
    if (s1.toLowerCase() === peg$c14) {
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e27); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = input.substr(peg$currPos, 5);
      if (s3.toLowerCase() === peg$c15) {
        peg$currPos += 5;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e28); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseF() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (s0.toLowerCase() === peg$c16) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e29); }
    }

    return s0;
  }

  function peg$parseFALSE() {
    var s0;

    s0 = input.substr(peg$currPos, 5);
    if (s0.toLowerCase() === peg$c17) {
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e30); }
    }

    return s0;
  }

  function peg$parseFUNCTION() {
    var s0;

    s0 = input.substr(peg$currPos, 8);
    if (s0.toLowerCase() === peg$c18) {
      peg$currPos += 8;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e31); }
    }

    return s0;
  }

  function peg$parseINCLUDE() {
    var s0;

    s0 = input.substr(peg$currPos, 7);
    if (s0.toLowerCase() === peg$c19) {
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e32); }
    }

    return s0;
  }

  function peg$parseINITIATORS() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 9);
    if (s1.toLowerCase() === peg$c20) {
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e33); }
    }
    if (s1 !== peg$FAILED) {
      s2 = input.charAt(peg$currPos);
      if (s2.toLowerCase() === peg$c21) {
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e34); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f12();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIF() {
    var s0;

    s0 = input.substr(peg$currPos, 2);
    if (s0.toLowerCase() === peg$c22) {
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e35); }
    }

    return s0;
  }

  function peg$parseIS() {
    var s0;

    s0 = input.substr(peg$currPos, 2);
    if (s0.toLowerCase() === peg$c23) {
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e36); }
    }

    return s0;
  }

  function peg$parseLOOKUP_VARIABLE() {
    var s0;

    s0 = input.substr(peg$currPos, 15);
    if (s0.toLowerCase() === peg$c24) {
      peg$currPos += 15;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e37); }
    }

    return s0;
  }

  function peg$parseOFF() {
    var s0;

    s0 = input.substr(peg$currPos, 3);
    if (s0.toLowerCase() === peg$c25) {
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e38); }
    }

    return s0;
  }

  function peg$parseON() {
    var s0;

    s0 = input.substr(peg$currPos, 2);
    if (s0.toLowerCase() === peg$c26) {
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e39); }
    }

    return s0;
  }

  function peg$parsePARAMETER_CHANGE() {
    var s0;

    s0 = input.substr(peg$currPos, 16);
    if (s0.toLowerCase() === peg$c27) {
      peg$currPos += 16;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e40); }
    }

    return s0;
  }

  function peg$parsePARAMETER_FILE() {
    var s0;

    s0 = input.substr(peg$currPos, 14);
    if (s0.toLowerCase() === peg$c28) {
      peg$currPos += 14;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e41); }
    }

    return s0;
  }

  function peg$parsePLOTFIL() {
    var s0;

    s0 = input.substr(peg$currPos, 7);
    if (s0.toLowerCase() === peg$c29) {
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e42); }
    }

    return s0;
  }

  function peg$parseSENSITIVITY() {
    var s0;

    s0 = input.substr(peg$currPos, 11);
    if (s0.toLowerCase() === peg$c30) {
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e43); }
    }

    return s0;
  }

  function peg$parseSET() {
    var s0;

    s0 = input.substr(peg$currPos, 3);
    if (s0.toLowerCase() === peg$c31) {
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e44); }
    }

    return s0;
  }

  function peg$parseSI() {
    var s0;

    s0 = input.substr(peg$currPos, 2);
    if (s0.toLowerCase() === peg$c32) {
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e45); }
    }

    return s0;
  }

  function peg$parseT() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (s0.toLowerCase() === peg$c33) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e46); }
    }

    return s0;
  }

  function peg$parseTIMER() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c34) {
      s0 = peg$c34;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e47); }
    }

    return s0;
  }

  function peg$parseTITLE() {
    var s0;

    s0 = input.substr(peg$currPos, 5);
    if (s0.toLowerCase() === peg$c35) {
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e48); }
    }

    return s0;
  }

  function peg$parseTRUE() {
    var s0;

    s0 = input.substr(peg$currPos, 4);
    if (s0.toLowerCase() === peg$c36) {
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e49); }
    }

    return s0;
  }

  function peg$parseUSEREVT() {
    var s0;

    s0 = input.substr(peg$currPos, 7);
    if (s0.toLowerCase() === peg$c37) {
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e50); }
    }

    return s0;
  }

  function peg$parseWHEN() {
    var s0;

    s0 = input.substr(peg$currPos, 4);
    if (s0.toLowerCase() === peg$c38) {
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e51); }
    }

    return s0;
  }

  function peg$parse___() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWhiteSpace();
    if (s2 === peg$FAILED) {
      s2 = peg$parseLineTerminatorSequence();
      if (s2 === peg$FAILED) {
        s2 = peg$parseSingleLineComment();
      }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseWhiteSpace();
        if (s2 === peg$FAILED) {
          s2 = peg$parseLineTerminatorSequence();
          if (s2 === peg$FAILED) {
            s2 = peg$parseSingleLineComment();
          }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f13(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parse__() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWhiteSpace();
    if (s2 === peg$FAILED) {
      s2 = peg$parseLineTerminatorSequence();
      if (s2 === peg$FAILED) {
        s2 = peg$parseSingleLineComment();
      }
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseWhiteSpace();
      if (s2 === peg$FAILED) {
        s2 = peg$parseLineTerminatorSequence();
        if (s2 === peg$FAILED) {
          s2 = peg$parseSingleLineComment();
        }
      }
    }
    peg$savedPos = s0;
    s1 = peg$f14(s1);
    s0 = s1;

    return s0;
  }

  function peg$parse_() {
    var s0, s1;

    s0 = [];
    s1 = peg$parseWhiteSpace();
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$parseWhiteSpace();
    }

    return s0;
  }

  function peg$parseArguments() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseExpressionType();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c39;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e52); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        s6 = peg$parseArguments();
        if (s6 !== peg$FAILED) {
          s3 = [s3, s4, s5, s6];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f15(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCallExpression() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseIdentifier();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 40) {
        s3 = peg$c40;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e53); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parseArguments();
        if (s4 === peg$FAILED) {
          s4 = null;
        }
        if (input.charCodeAt(peg$currPos) === 41) {
          s5 = peg$c41;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e54); }
        }
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f16(s1, s4);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExpressionOperator() {
    var s0;

    if (input.substr(peg$currPos, 2) === peg$c5) {
      s0 = peg$c5;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e9); }
    }
    if (s0 === peg$FAILED) {
      s0 = input.charAt(peg$currPos);
      if (peg$r12.test(s0)) {
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e55); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c42) {
          s0 = peg$c42;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e56); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c43) {
            s0 = peg$c43;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e57); }
          }
          if (s0 === peg$FAILED) {
            s0 = input.charAt(peg$currPos);
            if (peg$r13.test(s0)) {
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e58); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c44) {
                s0 = peg$c44;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e59); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseExpression() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseExpressionType();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseExpressionOperator();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseExpression();
        if (s5 === peg$FAILED) {
          s5 = peg$parseExpressionType();
        }
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f17(s1, s3, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExpressionBlock() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c40;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e53); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseExpression();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 41) {
          s3 = peg$c41;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e54); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f18(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExpressionType() {
    var s0;

    s0 = peg$parseCallExpression();
    if (s0 === peg$FAILED) {
      s0 = peg$parseExpressionBlock();
      if (s0 === peg$FAILED) {
        s0 = peg$parseVariable();
      }
    }

    return s0;
  }

  function peg$parseSpaceAssignment() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseCallExpression();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIdentifier();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseWhiteSpace();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseWhiteSpace();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parseWhiteSpace();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseExpr();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f19(s1, s4);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAssignment() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseCallExpression();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIdentifier();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 61) {
        s3 = peg$c45;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e60); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseExpr();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f20(s1, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIsExpression() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseVariable();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIS();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseExpr();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f21(s1, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAsExpression() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseVariable();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseAS();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseVariable();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f22(s1, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExpr() {
    var s0;

    s0 = peg$parseIsExpression();
    if (s0 === peg$FAILED) {
      s0 = peg$parseExpression();
      if (s0 === peg$FAILED) {
        s0 = peg$parseExpressionType();
      }
    }

    return s0;
  }

  function peg$parseVariable() {
    var s0;

    s0 = peg$parseCallExpression();
    if (s0 === peg$FAILED) {
      s0 = peg$parseLiteral();
      if (s0 === peg$FAILED) {
        s0 = peg$parseParameterName();
        if (s0 === peg$FAILED) {
          s0 = peg$parseIdentifier();
        }
      }
    }

    return s0;
  }

  function peg$parseStatement() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseSensitivityStatement();
    if (s1 === peg$FAILED) {
      s1 = peg$parseTitleStatement();
      if (s1 === peg$FAILED) {
        s1 = peg$parseFileStatement();
        if (s1 === peg$FAILED) {
          s1 = peg$parseBlockStatement();
          if (s1 === peg$FAILED) {
            s1 = peg$parseConditionalBlockStatement();
            if (s1 === peg$FAILED) {
              s1 = peg$parseAliasStatement();
              if (s1 === peg$FAILED) {
                s1 = peg$parsePlotFilStatement();
                if (s1 === peg$FAILED) {
                  s1 = peg$parseUserEvtStatement();
                  if (s1 === peg$FAILED) {
                    s1 = peg$parseFunctionStatement();
                    if (s1 === peg$FAILED) {
                      s1 = peg$parseTimerStatement();
                      if (s1 === peg$FAILED) {
                        s1 = peg$parseLookupStatement();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f23(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseSensitivityStatement() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSENSITIVITY();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse___();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseON();
        if (s3 === peg$FAILED) {
          s3 = peg$parseOFF();
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f24(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTitleStatement() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseTITLE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse___();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseTitleBlock();
        if (s4 !== peg$FAILED) {
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parseEND();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f25(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTitleBlock() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseEND();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseFreeCharacter();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseFreeCharacter();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseTitleBlock();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        peg$savedPos = s0;
        s0 = peg$f26(s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFileStatement() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsePARAMETER_FILE();
    if (s1 === peg$FAILED) {
      s1 = peg$parseINCLUDE();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = peg$parseFreeCharacter();
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseFreeCharacter();
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f27(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBlockStatement() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePARAMETER_CHANGE();
    if (s1 === peg$FAILED) {
      s1 = peg$parseINITIATORS();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse___();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseSourceElements();
        if (s4 !== peg$FAILED) {
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parseEND();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f28(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConditionalBlockStatement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseWHEN();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIF();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseExpr();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          s6 = peg$parseSourceElements();
          if (s6 !== peg$FAILED) {
            s7 = peg$parse___();
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          s6 = peg$parseEND();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f29(s1, s3, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAliasStatement() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseALIAS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse___();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseAliasBody();
        if (s4 !== peg$FAILED) {
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parseEND();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f30(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAliasBody() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseAsExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse___();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseAsExpression();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseAsExpression();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f31(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePlotFilStatement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsePLOTFIL();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = input.charAt(peg$currPos);
      if (peg$r6.test(s4)) {
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e16); }
      }
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = input.charAt(peg$currPos);
          if (peg$r6.test(s4)) {
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e16); }
          }
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          s6 = peg$parsePlotFilBody();
          if (s6 !== peg$FAILED) {
            s7 = peg$parse___();
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          s6 = peg$parseEND();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f32(s3, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePlotFilList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseVariable();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 44) {
        s5 = peg$c39;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e52); }
      }
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parsePlotFilList();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c39;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e52); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parsePlotFilList();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f33(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePlotFilBody() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsePlotFilList();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse___();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsePlotFilBody();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsePlotFilBody();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f34(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUserEvtStatement() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseUSEREVT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse___();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseUserEvtBody();
        if (s4 !== peg$FAILED) {
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parseEND();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f35(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUserEvtBody() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseUserEvtElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse___();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUserEvtElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUserEvtElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f36(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUserEvtElement() {
    var s0;

    s0 = peg$parseParameter();
    if (s0 === peg$FAILED) {
      s0 = peg$parseActionStatement();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSourceElement();
      }
    }

    return s0;
  }

  function peg$parseActionStatement() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$parseACTION();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (input.charCodeAt(peg$currPos) === 35) {
        s3 = peg$c10;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e23); }
      }
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = input.charAt(peg$currPos);
        if (peg$r6.test(s5)) {
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e16); }
        }
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = input.charAt(peg$currPos);
            if (peg$r6.test(s5)) {
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e16); }
            }
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s6 = peg$currPos;
            s7 = peg$parseUserEvtBody();
            if (s7 !== peg$FAILED) {
              s8 = peg$parse___();
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = null;
            }
            s7 = peg$parseEND();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f37(s4, s6);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFunctionStatement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseFUNCTION();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIdentifier();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        if (input.charCodeAt(peg$currPos) === 61) {
          s5 = peg$c45;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e60); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseExpr();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f38(s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTimerStatement() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseTimerLiteral();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLookupStatement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseLOOKUP_VARIABLE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseVariable();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          s6 = peg$parseLookupBody();
          if (s6 !== peg$FAILED) {
            s7 = peg$parse___();
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          s6 = peg$parseEND();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f40(s3, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLookupBody() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseReserved();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseFreeCharacter();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseFreeCharacter();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$parse___();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseLookupBody();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$parse___();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseLookupBody();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f41(s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseProgram() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseSourceElements();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    peg$savedPos = s0;
    s1 = peg$f42(s1);
    s0 = s1;

    return s0;
  }

  function peg$parseSourceElements() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseSourceElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse___();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSourceElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse___();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSourceElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f43(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSourceElement() {
    var s0;

    s0 = peg$parseStatement();
    if (s0 === peg$FAILED) {
      s0 = peg$parseSpaceAssignment();
      if (s0 === peg$FAILED) {
        s0 = peg$parseAssignment();
        if (s0 === peg$FAILED) {
          s0 = peg$parseAsExpression();
          if (s0 === peg$FAILED) {
            s0 = peg$parseIsExpression();
            if (s0 === peg$FAILED) {
              s0 = peg$parseExpression();
              if (s0 === peg$FAILED) {
                s0 = peg$parseCallExpression();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseExpressionBlock();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseParameterName();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseLiteral();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseIdentifier();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }


	function extractList(list, index) {
    	return list.map(i => i[index]);
    }
    function safeValue(value) {
    	return (value || [])[0] || [];
    }
    function stripLocations(o) {
      if (o) {
        delete o.location;
      }
      if (typeof o === 'object') {
        Object.values(o).forEach((v) => stripLocations(v));
      }
      return o;
    }

  peg$result = peg$startRuleFunction();

  if (options.peg$library) {
    return /** @type {any} */ ({
      peg$result,
      peg$currPos,
      peg$FAILED,
      peg$maxFailExpected,
      peg$maxFailPos
    });
  }
  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

const peg$allowedStartRules = [
  "Start"
];

export {
  peg$allowedStartRules as StartRules,
  peg$SyntaxError as SyntaxError,
  peg$parse as parse
};
